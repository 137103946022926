import {
    cleanStorage, getFutureRefresh,
    getPreviousPath,
    getRedirectUri, getState,
    removeAccessToken, removeFutureRefresh, removeIdToken,
    setAccessToken,
    setFutureRefresh, setIdToken
} from "./storage";
import {v4 as uuid} from 'uuid';

export const codeToToken = ({code, redirectUri, state, onSuccess, onError, apiGateway='https://beta-apigateway.dimu.org', sameSite=false}) => {
    const storedState = getState()
    if(state !== storedState) {
        if(onError) {
            onError('State mismatch.')
        } else {
            console.error('State mismatch.')
        }
        return
    }

    const callbackUrl = redirectUri ? redirectUri : getRedirectUri();
    const data = new URLSearchParams();
    data.append('code', code);
    data.append('grant_type', 'authorization_code');
    data.append('redirect_uri', callbackUrl);

    if(sameSite) {
        data.append('same_site', sameSite.toString())
    }

    fetch(`${apiGateway}/auths/oauth2/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Correlation-Id': uuid()
        },
        credentials: 'include',
        body: data,
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response.statusText)
            }
        })
        .then(data => {
            const previousPath = getPreviousPath();

            logUserIn({
                apiGateway: apiGateway,
                accessToken: data.access_token,
                expiresIn: data.expires_in,
                idToken: data.id_token
            })

            if(onSuccess) {
                onSuccess({previousPath: previousPath})
            }

            cleanStorage();
        })
        .catch(error => {
            if(onError) {
                onError(error)
            } else {
                console.error(error);
            }
        })
};

export const hasRefreshToken = ({apiGateway, onSuccess=null, onError=null}) => {
    fetch(`${apiGateway}/auths/oauth2/has-refresh-token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Correlation-Id': uuid()
        },
        credentials: 'include',
    })
        .then(response => {
            if(onSuccess) {
                onSuccess(response.ok);
            }
        })
        .catch(error => {
            if(onError) {
                onError(error);
            }
        })
}

export const refreshToken = ({apiGateway, onSuccess=null, onError=null}) => {
    const data = new URLSearchParams();
    data.append('grant_type', 'refresh_token');
    data.append('redirect_uri', getRedirectUri());

    fetch(`${apiGateway}/auths/oauth2/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Correlation-Id': uuid()
        },
        credentials: 'include',
        body: data,
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
        .then(data => {
            logUserIn({
                apiGateway: apiGateway,
                accessToken: data.access_token,
                expiresIn: data.expires_in,
                idToken: data.id_token
            })

            if(onSuccess) {
                onSuccess()
            }

        })
        .catch(error => {
            removeAccessToken()
            removeIdToken()
            clearTimeout(getFutureRefresh());
            removeFutureRefresh();
            if(onError) {
                onError(error);
            } else {
                console.error(error);
            }
        })
}

const logUserIn = ({apiGateway, accessToken, expiresIn, idToken=null}) => {
    setAccessToken(accessToken)
    if(idToken) {
        setIdToken(idToken)
    }


    const futureRefresh = setTimeout(() => {
        refreshToken({apiGateway: apiGateway})
    }, (expiresIn * 1000) - 60000);

    setFutureRefresh(futureRefresh);
}